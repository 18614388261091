import * as React from "react"
import { Link } from "gatsby"

import Layout from '../components/Showcase/layout';
import Seo from '../components/Showcase/seo';
import { StaticImage } from "gatsby-plugin-image"
import {ParallaxProvider, Parallax } from "react-scroll-parallax"


//* Fase 2: Add on-page navigation functionality 
// const handleClick = value => () => {
//   console.log(value)
//   const section = document.querySelector(`${value}`);
//   // console.log(section.id)
//   section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
// }

const InformationArchitecture = () => (
  <ParallaxProvider>

  <Layout>
    <Seo title="Information Architecture, Navigation & Global Content Strategy" />
    <section className="intro-section">
      <span className="tag-line">Content Audit | Information Architecture | Global Strategy</span>
      <h1>Information Architecture, Navigation & Global Content Strategy</h1>
      <p>Using the Foundational Strategy as a lens, we <strong>analyze existing content</strong> on the site. This uncovers gaps in content types, potential click path issues, disorganized or outdated content, and more. From here, we <strong>rebuild the information architecture</strong> of the site to ensure pages are intuitive to access and feature the best content.</p>
    </section>
    <section className="banner">
      
      <Parallax translateY={["50", "-30"]}>
     
      <StaticImage
        src="../assets/images/information-architecture/tamu-page-mapping.jpg"
        width={2200}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="TAMU Page Mapping"
        />
      </Parallax>
      <figcaption>TAMU Page Mapping</figcaption>
    </section>
    <section className="portfolio">
      <p className="intro">Content Audit - Behavior Flows - Site Map Evaluation - Information Architecture - URL Taxonomy - Page Selection - Dynamic Content Planning</p>

      <div className="cols">
        <div className="portfolio-card col-50">
          <figure>
          <StaticImage
          src="../assets/images/information-architecture/content-audit.jpg"
          width={1280}
          quality={100}
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
          alt="Content Audit"
          />
          
          </figure>
          <figcaption>Content Audit</figcaption>
        </div>
        <div id="scrolltohere" className="portfolio-card parallax col-50">
      <Parallax translateY={["50", "-30"]}>
      <figure>
        <StaticImage
        src="../assets/images/information-architecture/behavior-flow.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Behavior Flows"
        />
        
        </figure>
        </Parallax>
        <figcaption>Behavior Flows</figcaption>
      </div>
      </div>

      <div className="portfolio-card">
        <figure>
        <StaticImage
        src="../assets/images/information-architecture/interactive-sitemaps.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Interactive Sitemaps"
        />
        
        </figure>
        <figcaption>Interactive Sitemaps</figcaption>
      </div>


      <div className="cols">
        <div className="portfolio-card col-50">
        <Parallax translateY={["50", "-30"]}>
          <figure>
          <StaticImage
          src="../assets/images/information-architecture/information-architecture.jpg"
          width={1280}
          quality={100}
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
          alt="Information Architecture"
          />
          
          </figure>

        </Parallax>
          <figcaption>Information Architecture</figcaption>
        </div>
        <div className="portfolio-card parallax col-50">
     
      <figure>
        <StaticImage
        src="../assets/images/information-architecture/taxonomy-page-selection.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Taxonomy and Page Selection"
        />
        
        </figure>
        <figcaption>Taxonomy and Page Selection</figcaption>
      </div>
      </div>


      <div className="cols">
        <div className="portfolio-card col-50">
        
          <figure>
          <StaticImage
          src="../assets/images/information-architecture/dynamic-content-planning.jpg"
          width={1280}
          quality={100}
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
          alt="Dynamic Content Planning"
          />
          
          </figure>

       
          <figcaption>Dynamic Content Planning</figcaption>
        </div>
        <div className="portfolio-card parallax col-50">
        <Parallax translateY={["50", "-30"]}>
      <figure>
        <StaticImage
        src="../assets/images/information-architecture/category-tag-strategy.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Category and Tag Strategy"
        />
        
        </figure>
        </Parallax>
        <figcaption>Category and Tag Strategy</figcaption>
      </div>
      </div>


     
    </section>


    <section className="wrapper">
    <div className="section-title side-title">
        <h2>MORE DESIGN STEPS</h2>
        </div>
    <ul className="content-cards-wrapper">
    <li className="content-card">
            <Link to="/ux-design">
              <StaticImage
                src="../assets/images/ux-design-wireframes-prototyping-and-user-testing.jpg"
                width={700}
                quality={90}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Discovery and Planning"
              />
              <div className="text-container">
                <h3>UX Design: Wireframes, Prototyping & User Testing</h3>
                <p>Page Wireframes Development | Responsive Planning</p>
              </div>
            </Link>
          </li>
          <li className="content-card">
            <Link to="/visual-design">
              <StaticImage
                src="../assets/images/visual-design-link.jpg"
                width={700}
                quality={90}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Discovery and Planning"
              />
              <div className="text-container">
                <h3>Visual Design</h3>
                <p>Brand Standards | Design Exploration | Prototyping</p>
              </div>
            </Link>
          </li>
          </ul>
    </section>

    
  </Layout>
  </ParallaxProvider>
)

export default InformationArchitecture
